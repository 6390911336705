<a (click)="jumpToContent('main-content')" href="javascript:void(0)" class="skip-to-content-link">
  Skip to main content
</a>
<uc-agent-header-bar (openSidenav)="navBar.isOpen = true" hasSideNav="true"></uc-agent-header-bar>
<uc-side-nav #navBar [defaultOpen]="false"></uc-side-nav>

<div id="main-content" tabindex="-1" class="document-content">
  <div class="content-inner">
    <uc-form-template>
      <uc-go-back-link></uc-go-back-link>
      <uc-form-header title="{{ strings.header }}">
        <span [innerHTML]="strings.description"></span>
        <uc-message-banner type="info" *ngIf="conditionsWithDoc.length">
          <span [innerHTML]="helpText"></span>
        </uc-message-banner>
      </uc-form-header>

      <uc-section *ngIf="conditionsWithDoc.length">
        <uc-section-header [title]="strings.documentsSectionHeader"></uc-section-header>
        <uc-condition-item
          *ngFor="let condition of conditionsWithDoc"
          [year]="academicYear"
          [condition]="condition"
          [documentCategories]="documentCategories"
          (informationProvided)="notifyDocumentUploadForCondition($event)"></uc-condition-item>
      </uc-section>
      <uc-section *ngIf="conditionsWithoutDoc.length">
        <uc-section-header [title]="strings.actionRequiredHeader"></uc-section-header>
        <uc-condition-item
          *ngFor="let condition of conditionsWithoutDoc"
          [year]="academicYear"
          [condition]="condition"
          (informationProvided)="notifyDocumentUploadForCondition($event)"></uc-condition-item>
      </uc-section>
    </uc-form-template>
  </div>
  <uc-footer></uc-footer>
</div>
