import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { internalUrls } from '@constants/internalUrls';
import { User } from '@shared/models/user';
import { UserService } from '@shared/services/user/user.service';

@Injectable()
export class StudentOnlyRouteGuard {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  isStudentCurrentUser(student: User): boolean {
    return student && student.identifier === this.userService.user.identifier;
  }

  canAccess(): Observable<boolean> {
    return this.userService.userDetail.pipe(
      map((detail) => {
        const accessible = this.isStudentCurrentUser(detail.student);
        if (!accessible) {
          this.router.navigate(internalUrls.dashboard);
        }

        return !!accessible;
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canAccess();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
