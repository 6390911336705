import { Modal } from '@4sellers/ngx-modialog';
import { Component, OnInit } from '@angular/core';
import { type UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { UCError } from '@shared/models/errors';
import { User } from '@shared/models/user';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit {
  checkboxOptions: { labelText: string; value: string }[] = [];
  log: Logger;
  accountPage: UntypedFormGroup;
  currentUser: User;
  waiting = false;

  passwordErrorMessages: UCError[] = [];
  emailErrorMessages: UCError[] = [];
  successMessage: string[] = [];
  strings = strings.components.template.accountSettings;

  constructor(
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private modal: Modal,
    loggingService: LoggingService,
  ) {
    this.log = loggingService.createLogger(this);
  }

  get emailSendingEnabled(): boolean {
    return !!environment.features.sendEmail;
  }

  get changeEmailForm(): UntypedFormGroup {
    return this.accountPage.get('changeEmailForm') as UntypedFormGroup;
  }

  get changePasswordForm(): UntypedFormGroup {
    return this.accountPage.get('changePasswordForm') as UntypedFormGroup;
  }

  ngOnInit() {
    this.userService.currentUser.subscribe((u) => {
      this.currentUser = u;
    });

    this.accountPage = this.fb.group({
      changeEmailForm: this.fb.group({
        email: ['', Validators.required],
        password: ['', Validators.required],
      }),
      changePasswordForm: this.fb.group({
        oldPassword: ['', Validators.required],
        newPassword: ['', Validators.minLength(User.MIN_PASSWORD_LENGTH)],
        confirmPassword: ['', Validators.minLength(User.MIN_PASSWORD_LENGTH)],
      }),
    });
  }

  openEmailModal() {
    const newEmail = this.changeEmailForm.get('email').value;
    const password = this.changeEmailForm.get('password').value;
    if (!newEmail) {
      return;
    }

    if (!this.userService.isFirebaseEmailLogin) {
      this.emailErrorMessages = [{ code: 'auth.invalidAccountType', data: strings.errors.auth.invalidAccountType }];
      return;
    }

    this.modal
      .confirm()
      .size('lg')
      .isBlocking(true)
      .showClose(false)
      .title('Are you sure you want to update your email address?')
      .body(
        `You will be logged out and you will receive a verification email shortly after you click 'SEND VERIFICATION EMAIL'.
    Once you have accepted the verification email you will have to login using your new email address.`,
      )
      .okBtn('SEND VERIFICATION EMAIL')
      .cancelBtn('Cancel')
      .cancelBtnClass('cancel-btn')
      .open()
      .result.then(() => this.updateEmail(newEmail, password))
      .then(() => document.body.classList.remove('modal-open'));
  }

  updateEmail(newEmail, password) {
    this.userService
      .revalidate(password)
      .then(() => this.userService.updateEmail(newEmail))
      .then(() => this.userService.verifyEmail())
      .then(() => this.userService.logout())
      .catch((err: any) => {
        const ucError = this.userService.formatFirebaseError(err);

        if (ucError.code === 'auth.userNotFound') {
          ucError.code = 'auth.wrongPassword';
          ucError.data = strings.errors.auth.wrongPassword;
        }
        this.emailErrorMessages = [ucError];
        this.log.error(err);
      });
  }

  updatePassword() {
    this.passwordErrorMessages = [];
    this.successMessage = [];
    if (this.waiting) {
      return;
    }
    const oldPassword = this.changePasswordForm.get('oldPassword').value;
    const newPassword = this.changePasswordForm.get('newPassword').value;
    const confirmPassword = this.changePasswordForm.get('confirmPassword').value;

    if (this.changePasswordForm.status !== 'VALID') {
      this.passwordErrorMessages = [{ code: 'invalidForm', data: strings.errors.invalidForm }];
      return;
    }

    if (confirmPassword !== newPassword) {
      this.passwordErrorMessages = [{ code: 'auth.passwordsDontMatch', data: strings.errors.auth.passwordsDontMatch }];
      return;
    }
    this.waiting = true;

    this.userService
      .revalidate(oldPassword)
      .then(() => this.userService.updatePassword(newPassword))
      .then(() => {
        this.successMessage = [this.strings.changePassword.successMessage];
        this.waiting = false;
        this.changePasswordForm.reset();
      })
      .catch((err) => {
        const ucError = this.userService.formatFirebaseError(err);

        if (ucError.code === 'auth.userNotFound') {
          ucError.code = 'auth.wrongPassword';
          ucError.data = strings.errors.auth.wrongPassword;
        }
        this.waiting = false;
        this.passwordErrorMessages = [ucError];
        this.log.error(err);
      });
  }
}
