<a (click)="jumpToContent('main-content')" href="javascript:void(0)" class="skip-to-content-link">
  Skip to main content
</a>
<uc-agent-header-bar (openSidenav)="navBar.isOpen = true" hasSideNav="true"></uc-agent-header-bar>
<uc-side-nav #navBar [defaultOpen]="false"></uc-side-nav>

<div id="main-content" tabindex="-1" class="document-content">
  <div class="content-inner">
    <uc-form-template>
      <uc-go-back-link></uc-go-back-link>
      <uc-form-header title="{{ strings.header }}">
        <span [innerHTML]="strings.description"></span>
        <uc-message-banner type="info">
          <span [innerHTML]="helpText"></span>
        </uc-message-banner>
      </uc-form-header>
      <uc-category-file-upload></uc-category-file-upload>
      <uc-file-list-container *ngFor="let category of allowedCategories" [category]="category"></uc-file-list-container>
    </uc-form-template>
  </div>

  <uc-footer></uc-footer>
</div>
