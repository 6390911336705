import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AgentOrganismsModule } from '@app/components/organisms/agent-organisms.module';
import { AtomsModule } from '@shared/components/atoms/atoms.module';
import { MoleculesModule } from '@shared/components/molecules/molecules.module';
import { OrganismsModule } from '@shared/components/organisms/organisms.module';
import { SharedTemplatesModule } from '@shared/components/templates/templates.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AtomsModule,
    MoleculesModule,
    OrganismsModule,
    SharedTemplatesModule,
    AgentOrganismsModule,
  ],
})
export class TemplatesModule {}
