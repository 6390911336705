<uc-header-bar (openSidenav)="navBar.isOpen = true"></uc-header-bar>
<uc-side-nav #navBar></uc-side-nav>
<div id="main-content" tabindex="-1" class="account-settings-content" [formGroup]="accountPage">
  <div class="content-inner">
    <uc-form-template>
      <uc-go-back-link></uc-go-back-link>
      <uc-form-header title="{{ strings.header }}">
        <uc-message-banner class="heading-banners">
          {{ strings.mandatoryBanner }}
          <uc-required-field></uc-required-field>
        </uc-message-banner>
      </uc-form-header>

      <uc-section>
        <uc-section-header [title]="strings.changeEmail.sectionHeader"></uc-section-header>

        <uc-subsection *ngIf="emailSendingEnabled">
          <form formGroupName="changeEmailForm">
            <uc-form-input>
              <section class="errors">
                <div *ngFor="let err of emailErrorMessages" class="error">
                  <uc-message-banner type="error">
                    <span [innerHTML]="err | errorMessage"></span>
                  </uc-message-banner>
                </div>
              </section>

              <div *ngIf="currentUser?.email">
                <label>{{ strings.changeEmail.currentEmail }}</label>
                <div class="read-only-text">{{ currentUser?.email }}</div>
              </div>

              <uc-string-input
                testSelector="account-email"
                required="yes"
                [label]="strings.changeEmail.emailLabel"
                formControlName="email"></uc-string-input>

              <uc-subsection-question>{{ strings.changeEmail.passwordInfo }}</uc-subsection-question>

              <uc-password-input
                testSelector="change-email"
                required="yes"
                [label]="strings.changeEmail.passwordLabel"
                controlName="password"
                [form]="changeEmailForm"></uc-password-input>
            </uc-form-input>

            <uc-message-banner type="warning" class="banner-spacing">
              <span [innerHTML]="strings.changeEmail.infoBanner"></span>
            </uc-message-banner>

            <uc-button
              testSelector="open-email-modal"
              (click)="openEmailModal()"
              class="rounded inline-icon-btn secondary">
              <uc-icon iconType="cog" iconWidth="22" iconHeight="22" iconFill="$colorWhite"></uc-icon>
              {{ strings.changeEmail.changeButton }}
            </uc-button>
          </form>
        </uc-subsection>

        <uc-message-banner *ngIf="!emailSendingEnabled" type="error" class="banner-spacing">
          <span [innerHTML]="strings.changeEmail.disabled"></span>
        </uc-message-banner>
      </uc-section>
    </uc-form-template>

    <uc-form-template>
      <uc-section>
        <uc-section-header [title]="strings.changePassword.header"></uc-section-header>

        <uc-subsection>
          <form formGroupName="changePasswordForm">
            <uc-form-input>
              <section class="errors">
                <div *ngFor="let err of passwordErrorMessages" class="error">
                  <uc-message-banner type="error">
                    <span [innerHTML]="err | errorMessage"></span>
                  </uc-message-banner>
                </div>
              </section>

              <section class="success">
                <div *ngFor="let msg of successMessage" class="success">
                  <uc-message-banner type="success">
                    <span [innerHTML]="msg"></span>
                  </uc-message-banner>
                </div>
              </section>

              <uc-password-input
                testSelector="change-existing"
                required="yes"
                [label]="strings.changePassword.existingPassword"
                controlName="oldPassword"
                [form]="changePasswordForm"></uc-password-input>

              <uc-password-input
                testSelector="set-new"
                required="yes"
                [label]="strings.changePassword.newPassword"
                controlName="newPassword"
                [form]="changePasswordForm"></uc-password-input>

              <uc-password-input
                testSelector="confirm-change"
                required="yes"
                [label]="strings.changePassword.confirmPassword"
                controlName="confirmPassword"
                [form]="changePasswordForm"></uc-password-input>
            </uc-form-input>

            <uc-button
              testSelector="update-password"
              (click)="updatePassword()"
              class="rounded inline-icon-btn secondary">
              <uc-icon iconType="cog" iconWidth="22" iconHeight="22" iconFill="$colorWhite"></uc-icon>
              {{ strings.changePassword.updatePassword }}
            </uc-button>
          </form>
        </uc-subsection>
      </uc-section>
    </uc-form-template>
  </div>
  <uc-footer></uc-footer>
</div>
