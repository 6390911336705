{
  "leads": [
    {
      "process_name": {
        "code": "new_student"
      },
      "first_name": "Mike",
      "surname": "Wazowski",
      "user_id": "g4Dgpcn3IUafbcOrEXzJiUsIJjKPMR",
      "academic_year": {
        "code": "2018"
      },
      "state": {
        "code": "in_progress"
      },
      "qualifications": [
        {
          "code": "BA",
          "priority": 1,
          "qualification_occurrence": "foo"
        },
        {
          "code": "LLB",
          "priority": 2
        }
      ],
      "birth_date": "2004-01-01",
      "date_created": "2018-05-25T11:19:37.129162+12:00",
      "date_submitted": null,
      "agent_name": "Roz",
      "agent_agency": "Monsters inc.",
      "draft": false,
      "email_address": "mike@email.com",
      "study_start": {
        "code": "Semester 1"
      },
      "has_applied_conditions": true,
      "actions": {
        "primary": {
          "label": "Resume",
          "action": "RESUME",
          "href": null
        },
        "secondary": [
          {
            "label": "",
            "action": "HAS_APPLIED_CONDITIONS",
            "href": null
          },
          {
            "label": "",
            "action": "UPLOAD_DOCS",
            "href": null
          },
          {
            "label": "",
            "action": "WITHDRAW",
            "href": null
          }
        ]
      }
    },
    {
      "process_name": {
        "code": "new_student"
      },
      "first_name": "James",
      "surname": "Sullivan",
      "user_id": "sdfgDSFG34SDFG3tjdfg34DFJ76dgD",
      "academic_year": {
        "code": "2018"
      },
      "state": {
        "code": "has_offer"
      },
      "student_id": "32142",
      "qualifications": [],
      "birth_date": "2003-01-01",
      "date_created": "2018-05-24T11:30:37.129162+12:00",
      "date_submitted": "2018-05-25T11:30:37.129162+12:00",
      "agent_name": "Roz",
      "agent_agency": "Monsters inc.",
      "draft": true,
      "email_address": "james@email.com",
      "study_start": {
        "code": "Other"
      },
      "study_start_other": "2004-01-01",
      "actions": {
        "primary": null,
        "secondary": [
          {
            "label": "View Documents",
            "action": "DOCUMENTS_AVAILABLE",
            "href": null
          }
        ]
      }
    },
    {
      "process_name": {
        "code": "incoming_student_exchange_study_abroad"
      },
      "first_name": "Baby",
      "surname": "Boo",
      "user_id": "jydfhe32sdfh76rtfg7ewesdfHJFTT",
      "academic_year": {
        "code": "2019"
      },
      "state": {
        "code": "in_progress"
      },
      "qualifications": [
        {
          "code": "BA",
          "priority": 1,
          "qualificationOccurrence": "fum"
        }
      ],
      "student_id": "12345",
      "birth_date": "2005-01-01",
      "date_created": "2018-05-25T11:30:37.129162+12:00",
      "date_submitted": "2018-05-26T11:30:37.129162+12:00",
      "agent_name": "Roz",
      "agent_agency": "Monsters inc.",
      "draft": true,
      "email_address": "boo@email.com",
      "actions": {
        "primary": {
          "label": "Resume",
          "action": "RESUME",
          "href": null
        },
        "secondary": []
      }
    },
    {
      "process_name": {
        "code": "incoming_student_exchange_study_abroad"
      },
      "first_name": "Haz",
      "surname": "Offer",
      "user_id": "jydfhe32sdfh76rtaoiwesdfHJFTT",
      "academic_year": {
        "code": "2019"
      },
      "state": {
        "code": "in_progress"
      },
      "qualifications": [
        {
          "code": "BA",
          "priority": 1,
          "qualificationOccurrence": "fum"
        },
        {
          "code": "BCOM",
          "priority": 2,
          "qualificationOccurrence": "foo"
        }
      ],
      "student_id": "12345",
      "birth_date": "2005-01-01",
      "date_created": "2018-05-28T11:30:37.129162+12:00",
      "date_submitted": "2018-05-29T11:30:37.129162+12:00",
      "agent_name": "Roz",
      "agent_agency": "Monsters inc.",
      "draft": true,
      "email_address": "haz@email.com",
      "actions": {
        "primary": {
          "label": "Resume",
          "action": "RESUME",
          "href": null
        },
        "secondary": [
          {
            "label": "View Documents",
            "action": "DOCUMENTS_AVAILABLE",
            "href": null
          }
        ]
      }
    }
  ]
}
