import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import strings from '@constants/strings.constants';
import { environment } from '@environment';
import { UnsubscribeOnDestroy } from '@shared/classes/unsubscribe-on-destroy';
import { internalUrls } from '@shared/constants/internalUrls';
import { Condition } from '@shared/models/condition';
import { REFDATA_TYPES } from '@shared/models/reference-data';
import { Category } from '@shared/models/uc-file';
import { UserTypes } from '@shared/models/user';
import { ConditionService } from '@shared/services/condition/condition.service';
import { DocumentService } from '@shared/services/document/document.service';
import { FlashMessageService } from '@shared/services/flash-message/flash-message.service';
import { LoggingService, Logger } from '@shared/services/logging/logging.service';
import { ProcessService } from '@shared/services/process/process.service';
import { ReferenceDataService } from '@shared/services/reference-data/reference-data.service';

@Component({
  selector: 'uc-information-required-template',
  templateUrl: './information-required-template.component.html',
  styleUrls: ['./information-required-template.component.scss'],
})
export class InformationRequiredComponent extends UnsubscribeOnDestroy implements OnInit {
  log: Logger;
  strings = strings.components.template.informationRequiredPage;
  helpText = strings.components.organisms.fileUpload.helpText;
  academicYear;
  conditionsForYear: Condition[] = [];
  conditionsWithoutDoc: Condition[] = [];
  conditionsWithDoc: Condition[] = [];
  routeParams = { year: '' };
  refdataToPreload = [REFDATA_TYPES.CONDITION_REASON, REFDATA_TYPES.CONDITION_STATE_STUDENT];
  documentCategories: Category[];
  openMenu = environment.scope === UserTypes.agent;

  constructor(
    private conditionService: ConditionService,
    private activatedRoute: ActivatedRoute,
    private refDataService: ReferenceDataService,
    private router: Router,
    private flashMessageService: FlashMessageService,
    loggingService: LoggingService,
    private processService: ProcessService,
    private documentService: DocumentService,
  ) {
    super();
    this.log = loggingService.createLogger(this);
  }

  ngOnInit() {
    const observables: Observable<any>[] = [
      this.activatedRoute.params,
      this.conditionService.requiredConditions.pipe(filter((conds) => Object.keys(conds).length > 0)),
      this.documentService.getCategories(),
    ];
    // Preload refdata, make sure it is here before rendering conditions
    this.refdataToPreload.forEach((refdata) => observables.push(this.refDataService.getByType(refdata)));

    combineLatest(...observables)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(([params, conditions, documentCategories]) => {
        this.academicYear = params.year;
        // Guard against an invalid year parameter
        if (!this.academicYear.match(/^[0-9]{4}$/)) {
          return this.router.navigate(internalUrls.dashboard);
        }
        this.documentCategories = documentCategories;

        this.conditionsForYear = conditions[this.academicYear];
        const hasConditions = !!(this.conditionsForYear && this.conditionsForYear.length);
        this.conditionsWithoutDoc = this.conditionsForYear?.filter((condition) => !condition.documentTypeCode);
        this.conditionsWithDoc = this.conditionsForYear?.filter((condition) => condition.documentTypeCode);
        if (!hasConditions) {
          this.flashMessageService.pushWarning(this.strings.noInfoNeededMessage(this.academicYear, { countdown: 10 }));
          this.router.navigate(internalUrls.dashboard);
        }
      });
  }

  notifyDocumentUploadForCondition(fileAndCondition: { fileId: string; condition: Condition }) {
    const { fileId, condition } = fileAndCondition;
    this.processService
      .notifyDocumentUpload(fileId, this.academicYear, condition)
      .subscribe(() => this.refreshConditionItems());
  }

  refreshConditionItems() {
    this.conditionService.refreshConditionsForYear(this.academicYear);
  }

  jumpToContent(jumpToElement: string): void {
    document.getElementById(jumpToElement).focus();
  }
}
