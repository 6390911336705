<uc-agent-header-bar (openSidenav)="navBar.isOpen = true" hasSideNav="true"></uc-agent-header-bar>
<uc-side-nav #navBar [defaultOpen]="false"></uc-side-nav>
<div id="main-content" tabindex="-1" class="dashboard-content">
  <div class="table-controls-wrapper">
    <uc-button testSelector="new-application" [routerLink]="internalUrls.preProcessPage('new_student')">
      {{ strings.newApplicationButton }}
    </uc-button>

    <form [formGroup]="formGroup" class="filter__wrapper">
      <div class="filter__input">
        <label for="application-year-filter">{{ strings.applicationYearLabel }}</label>
        <div class="select-content">
          <select id="application-year-filter" formControlName="academicYear">
            <option [value]="''">{{ strings.allYears }}</option>
            <option *ngFor="let year of applicationYears" [value]="year">
              {{ year }}
            </option>
          </select>
        </div>
      </div>
      <uc-string-input
        testSelector="email"
        noIcon="true"
        class="filter__input filter__input--email"
        formControlName="emailAddress"
        [label]="strings.emailAddressLabel"></uc-string-input>
      <uc-button testSelector="filter" (click)="filterLeads()" class="secondary rounded filter-btn">
        {{ strings.filterBtn }}
      </uc-button>
      <a (click)="resetFilters()" class="reset-btn">{{ strings.resetBtn }}</a>
    </form>
  </div>

  <uc-spinner *ngIf="initialLoadingSpinner" size="40" [useStaticPositioning]="true"></uc-spinner>
  <ngx-datatable
    *ngIf="!initialLoadingSpinner"
    [loadingIndicator]="loading"
    [rows]="leads"
    [columnMode]="'flex'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="getRowHeight"
    [sortType]="'single'"
    [externalPaging]="true"
    [externalSorting]="true"
    [count]="page.totalItems"
    [offset]="page.pageNumber"
    [limit]="page.itemsPerPage"
    (page)="setPage($event)"
    (sort)="onSort($event)">
    <ngx-datatable-column
      [width]="10"
      [sortable]="false"
      [canAutoResize]="false"
      [draggable]="true"
      [resizeable]="true"></ngx-datatable-column>
    <ngx-datatable-column name="{{ strings.columns.appId }}" prop="userId" [flexGrow]="1" [sortable]="true">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <uc-copy [value]="row.userId" [stringLength]="6" [title]="row.userId"></uc-copy>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="{{ strings.columns.studentId }}"
      prop="studentId"
      [flexGrow]="1"
      [sortable]="true"></ngx-datatable-column>
    <ngx-datatable-column
      name="{{ strings.columns.familyName }}"
      prop="surname"
      [flexGrow]="1"
      [sortable]="true"></ngx-datatable-column>
    <ngx-datatable-column
      name="{{ strings.columns.givenName }}"
      prop="firstName"
      [flexGrow]="1"
      [sortable]="true"></ngx-datatable-column>
    <ngx-datatable-column
      name="{{ strings.columns.dob }}"
      prop="displayBirthDate"
      [flexGrow]="1"
      [sortable]="true"></ngx-datatable-column>
    <ngx-datatable-column
      name="{{ strings.columns.email }}"
      prop="emailAddress"
      [flexGrow]="1"
      [sortable]="true"></ngx-datatable-column>
    <ngx-datatable-column
      name="{{ strings.columns.year }}"
      prop="academicYear.code"
      [flexGrow]="0.5"
      [sortable]="true"></ngx-datatable-column>
    <ngx-datatable-column
      name="{{ strings.columns.intake }}"
      prop="intakeLabel"
      [flexGrow]="1.5"
      [sortable]="false"></ngx-datatable-column>
    <ngx-datatable-column
      name="{{ strings.columns.qual }}"
      prop="qualificationCodes"
      [flexGrow]="1"
      [sortable]="false"></ngx-datatable-column>
    <ngx-datatable-column name="{{ strings.columns.status }}" prop="stateLabel" [flexGrow]="1.2" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <uc-tag [class]="getStatusColour(row?.stateLabel)">{{ appStatusStrings[row?.stateLabel] }}</uc-tag>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="{{ strings.columns.created }}"
      prop="displayDateCreated"
      [flexGrow]="1"
      [sortable]="true"></ngx-datatable-column>
    <ngx-datatable-column
      name="{{ strings.columns.submitted }}"
      prop="displayDateSubmitted"
      [flexGrow]="1"
      [sortable]="true"></ngx-datatable-column>
    <ngx-datatable-column name="{{ strings.columns.action }}" prop="actions" [flexGrow]="1.2" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <a
          *ngIf="row.hasInfoRequired"
          class="lead-table__action_pill"
          (click)="viewInfoRequired(row)"
          href="javascript:void(0)">
          <uc-tag class="red">{{ strings.infoRequired }}</uc-tag>
        </a>
        <a
          *ngIf="row.hasDocumentsAvailable"
          class="lead-table__action_pill"
          (click)="viewDocuments(row)"
          href="javascript:void(0)">
          <uc-tag class="red">{{ strings.documentsAvailable }}</uc-tag>
        </a>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column
      name="{{ strings.columns.agent }}"
      prop="agentName"
      [flexGrow]="0.8"
      [sortable]="false"></ngx-datatable-column>
    <ngx-datatable-column name="" prop="leads?.actions" [flexGrow]="0.5" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <uc-action-menu
          *ngIf="buildActionObjects(row).length"
          [modelData]="row"
          [actions]="buildActionObjects(row)"></uc-action-menu>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

  <uc-footer-minimal [fullPageView]="true"></uc-footer-minimal>
</div>
