import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { internalUrls } from '@shared/constants/internalUrls';

@Injectable()
export class AgentApplicantModuleAccessGuard {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    // Routes with this guard on must have a lockedRouteRegexs array which is an array of regexs
    let routes = next.data.lockedRoutes;
    routes =
      routes &&
      routes.filter((element) => {
        return state.url.match(new RegExp(`/${element}/?$`));
      });

    if (routes.length) {
      this.router.navigate(internalUrls.dashboard);
    }

    return routes ? !routes.length : true;
  }
}
