import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import strings from '@constants/strings.constants';
import { externalUrls } from '@constants/urls.constants';
import { APP_NAMES } from '@shared/constants/app-names.constants';
import { internalUrls } from '@shared/constants/internalUrls';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'uc-login-template',
  templateUrl: './login-template.component.html',
  styleUrls: ['./login-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginTemplateComponent implements OnInit {
  strings = strings.components.organisms;
  urls = externalUrls;
  appNameEnv = APP_NAMES.agent;

  constructor(
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.userService.isInOutage.subscribe((inOutage) => {
      if (inOutage) {
        this.router.navigate(internalUrls.outage);
      }
    });
  }
}
